import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import AccessDeniedComponent from "./content/auth/access-denied/access-denied.component";
import BaseComponent from "./content/base/base.component";
import ContractCreateComponent from "./content/contract/contract-create/contract-create.component";
import ContractSummaryComponent from "./content/contract/contract-summary/contract-summary.component";
import ContractUserSummaryComponent from "./content/contract/contractUser-summary/contractUser-summary.component";
import ErrorComponent from "./content/error/error.component";
import SingoutComponent from "./content/singout/singout.component";
import UserListComponent from "./content/user/user-list/user-list.component";
import ContractResolver from "./resolvers/contract-resolver";
import ContractUserResolver from "./resolvers/contractUser-resolver";
import ErrorResolve from "./resolvers/error-resolver";
import LoginUserResolve from "./resolvers/login-user-resolver";
import { UserListResolver } from "./resolvers/user-list.resolver";
import RouteParams from "./shared/route-params";
import { BrowserUtils } from "@azure/msal-browser";

const routes: Routes = [
  {
    path: "unauthorized",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  {
    path: `error/:${RouteParams.ErrorCode}`,
    component: ErrorComponent,
    canActivate: [MsalGuard],
    resolve: { loginuser: ErrorResolve },
  },
  {
    path: "sign-out",
    component: SingoutComponent,
    pathMatch: "full",
  },
  {
    path: "logout",
    component: SingoutComponent,
    pathMatch: "full",
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [MsalGuard],
    runGuardsAndResolvers: "always",
    resolve: { loginuser: LoginUserResolve },
    children: [
      {
        path: "users",
        component: UserListComponent,
        runGuardsAndResolvers: "always",
        resolve: { userData: UserListResolver },
        canActivate: [MsalGuard],
      },
      {
        path: "contracts",
        component: ContractSummaryComponent,
        runGuardsAndResolvers: "always",
        resolve: { contractData: ContractResolver },
        canActivate: [MsalGuard],
      },
      {
        path: `contracts/:${RouteParams.ItemId}`,
        component: ContractCreateComponent,
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard],
      },
      {
        path: `contractUser/:${RouteParams.ItemId}`,
        component: ContractUserSummaryComponent,
        runGuardsAndResolvers: "always",
        resolve: { contractUserData: ContractUserResolver },
        canActivate: [MsalGuard],
      },
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "error/404",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    }),
  ],

  exports: [RouterModule],
})
export default class AppRoutingModule {}
