/* eslint-disable prefer-destructuring */
import { IPContractCreateItem } from "../api-generated";
import { TPContract } from "../api-generated/model/tPContract";
import ContractApplicationCreate from "./contractApplicationCreate";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class Contract
  extends TimeStampWithDraft
  implements IPContractCreateItem
{
  public Name?: string;

  public Code?: string;

  public BoroughId: string;

  public Pin?: string;

  public DocControlEmail?: string;

  public PMISSupportEmail?: string;

  public AHJVWorkSpaceUrl?: string;

  public WorkSpaceUrl?: string;

  public ActivityUrl?: string;

  public Bim360Url?: string;

  public DBTCollaborationWorkspaceURL?: string;

  public NTPDate?: string;

  public FMSID?: string;

  public AHJVPMId?: string;

  public AHJVPMName?: string;

  public AHJVPMEmail?: string;

  public DDCPMId?: string;

  public DDCPMName?: string;

  public DDCPMEmail?: string;

  public DBPMId?: string;

  public DBPMName?: string;

  public DBPMEmail?: string;

  public BluebeamManagerId?: string;

  public BluebeamManagerName?: string;

  public BluebeamManagerEmail?: string;

  public InActive?: boolean;

  public Guid?: string;

  public ContractApplications: ContractApplicationCreate[];

  constructor(item?: TPContract) {
    super(item);
    this.Name = item?.Name;
    this.Code = item?.Code;
    this.BoroughId = item?.BoroughId;
    this.Pin = item?.Pin;
    this.DocControlEmail = item?.DocControlEmail;
    this.PMISSupportEmail = item?.PMISSupportEmail;
    this.AHJVWorkSpaceUrl = item?.AHJVWorkSpaceUrl;
    this.WorkSpaceUrl = item?.WorkSpaceUrl;
    this.ActivityUrl = item?.ActivityUrl;
    this.Bim360Url = item?.Bim360Url;
    this.DBTCollaborationWorkspaceURL = item?.DBTCollaborationWorkspaceURL;
    this.NTPDate = item?.NTPDate;
    this.FMSID = item?.FMSID;
    this.AHJVPMId = item?.AHJVPMId;
    this.AHJVPMName = item?.AHJVPMName;
    this.AHJVPMEmail = item?.AHJVPMEmail;
    this.DDCPMId = item?.DDCPMId;
    this.DDCPMName = item?.DDCPMName;
    this.DDCPMEmail = item?.DDCPMEmail;
    this.DBPMId = item?.DBPMId;
    this.DBPMName = item?.DBPMName;
    this.DBPMEmail = item?.DBPMEmail;
    this.BluebeamManagerId = item?.BluebeamManagerId;
    this.BluebeamManagerName = item?.BluebeamManagerName;
    this.BluebeamManagerEmail = item?.BluebeamManagerEmail;
    this.InActive = item?.InActive ?? false;
    this.Guid = item?.Guid;
    if (item?.contract_application && item.contract_application.length > 0) {
      this.ContractApplications = item.contract_application.map((ca) => {
        return new ContractApplicationCreate(ca);
      });
    } else {
      this.ContractApplications = [];
    }
  }
}
