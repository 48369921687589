/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import {
  IMenuItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ContractService } from "src/app/api-generated";
import { IPContractApplicationCreateItem } from "src/app/api-generated/model/iPContractApplicationCreateItem";
import { IPContractCreateItem } from "src/app/api-generated/model/iPContractCreateItem";
import { TPApplication } from "src/app/api-generated/model/tPApplication";
import { TPContract } from "src/app/api-generated/model/tPContract";
import LoginUser from "src/app/models/loginUser";
import LoadingService from "src/app/services/loading.service";
import LocalApplicationService from "src/app/services/local-application.service";
import LocalBoroughService from "src/app/services/local-borough.service";
import LocalContractService from "src/app/services/local-contract.service";
import { docModuleId } from "src/app/shared/staticValue";
import NavService from "src/app/services/nav.service";

@Component({
  selector: "app-contract-edit",
  templateUrl: "./contract-edit.component.html",
  styleUrls: ["./contract-edit.component.css"],
})
export default class ContractEditComponent implements OnInit {
  @Input() contract: TPContract;

  @Output() close = new EventEmitter<void>();

  @Input() firstOpen: boolean;

  @Output() firstOpenChange = new EventEmitter<boolean>();

  @Input() activeSave: boolean;

  @Output() activeSaveChange = new EventEmitter<boolean>();

  @Input() currentPath: string;

  contractForm: UntypedFormGroup;

  submitted = false;

  boroughList: IMenuItem[];

  statusList: IMenuItem[];

  contractExist = false;

  applicationList: TPApplication[];

  private currentUser: LoginUser;

  private contractList: TPContract[];

  constructor(
    private router: Router,
    private contractService: ContractService,
    private localContractService: LocalContractService,
    private localBoroughService: LocalBoroughService,
    private localApplicationService: LocalApplicationService,
    public navService: NavService,
    private loadingService: LoadingService,
    private activeModal: ModalContainerService,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.navService.getUser();
    this.contractList = this.localContractService.getItems();

    this.boroughList = this.localBoroughService.getItems().map((b) => {
      return { id: b.Guid, name: b.Name, checked: false };
    });

    this.statusList = [
      { id: "active", name: "Active", checked: false },
      { id: "disable", name: "Disable", checked: false },
    ];

    this.applicationList = this.localApplicationService
      .getItems()
      .filter((app) => app.ModuleId === docModuleId);

    this.applicationList.sort((a, b) => a.Order - b.Order);

    this.contractForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      borough: new UntypedFormControl(null, Validators.required),
      pin: new UntypedFormControl(null),
      apps: new UntypedFormArray(
        this.applicationList.map((app) => new UntypedFormControl(false)),
      ),
      disabled: new UntypedFormControl(null),
    });

    console.log("contract", this.contract);

    if (this.contract) {
      this.contractForm.patchValue({
        name: this.contract.Name,
        borough: this.contract.BoroughId,
        pin: this.contract.Pin,
        apps: this.applicationList.map((app) =>
          this.contract.contract_application
            .map((c) => c.ApplicationId)
            .includes(app.Guid),
        ),
        disabled: !!this.contract.DateDeleted,
      });
    }
  }

  get f(): UntypedFormGroup["controls"] {
    return this.contractForm.controls;
  }

  get appsArray(): UntypedFormArray {
    return <UntypedFormArray>this.contractForm.get("apps");
  }

  onSubmit(): void {
    if (!this.activeSave) {
      return;
    }

    this.submitted = true;
    // console.log(this.submitted);
    // stop here if form is invalid
    this.checkExist();

    if (this.contractForm.invalid || this.contractExist) {
      return;
    }

    this.update();
  }

  update(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.title = "Notification";
    modalInstance.instance.body =
      "Are you sure you would like to save the changes?";
    modalInstance.instance.theme = "light";
    this.firstOpenChange.emit(true);

    modalInstance.result.then(async (result) => {
      if (result === 1) {
        this.loadingService.start();

        const name = this.contractForm.get("name").value;
        const borough = this.contractForm.get("borough").value;
        const pin = this.contractForm.get("pin").value;
        const disabled = this.contractForm.get("disabled").value;
        let selectedAppId = "";

        const newContract = JSON.parse(JSON.stringify(this.contract));
        newContract.Name = name;
        newContract.BoroughId = this.boroughList.find(
          (b) => b.id === borough,
        )?.id;

        if (disabled) {
          newContract.DeletedBy = this.currentUser.Id;
          newContract.DateDeleted = new Date().toISOString();
        }

        if (pin) {
          newContract.Pin = pin;
        }

        this.applicationList.forEach((app, i) => {
          if (this.appsArray.value[i]) {
            selectedAppId += `${app.Guid},`;
          }
        });

        if (selectedAppId !== "") {
          selectedAppId = selectedAppId.slice(0, -1);
        }

        newContract.ContractApplications = selectedAppId.split(",").map((c) => {
          return { ApplicationId: c } as IPContractApplicationCreateItem;
        });

        this.contractService.updateContract(newContract).subscribe(
          (contract) => {
            this.loadingService.stop();

            if (contract) {
              this.submitted = false;

              console.log("currentPath", this.currentPath);

              if (disabled || this.currentPath === "contractList") {
                this.router.navigate(["contracts"]);
              } else {
                this.router.navigate([`/contractUser/${this.contract.Guid}`]);
              }
            }
          },
          (error: HttpErrorResponse) => {
            this.errorHandle(error);
          },
        );
      }
    });
  }

  checkExist(): void {
    const name = this.contractForm.get("name").value;

    if (
      name &&
      this.contractList.filter((d) => {
        return (
          d.Name.toUpperCase() === name.toUpperCase() &&
          !d.DateDeleted &&
          d.Guid !== this.contract.Guid
        );
      }).length > 0
    ) {
      this.contractExist = true;
    }
  }

  onInputChange(): void {
    this.activeSaveChange.emit(true);
  }

  cancel(): void {
    // const modalInstance = this.activeModal.open(NotificationComponent);
    // modalInstance.instance.title = "Notification";
    // modalInstance.instance.body = "Are you sure you would like to cancel?";
    // modalInstance.instance.theme = "light";
    // modalInstance.result.then(async(result) => {
    //   if (result == 1) {
    //     this.back();
    //   }
    // });
    this.close.emit();
  }

  onBoroughChange(e: IMenuItem | IMenuItem[]): void {
    if (
      e &&
      !Array.isArray(e) &&
      this.contractForm.get("borough").value !== e.id
    ) {
      this.contractForm.patchValue({ borough: e.id });
      this.activeSaveChange.emit(true);
    }
  }

  onStatusChange(e: IMenuItem | IMenuItem[]): void {
    if (
      e &&
      !Array.isArray(e) &&
      this.contractForm.get("disabled").value !== null &&
      this.contractForm.get("disabled").value !== (e.id === "disable")
    ) {
      this.contractForm.patchValue({
        disabled: e.id === "disable",
      });
      // console.log(e.id==='disable'?true:false);
      this.activeSaveChange.emit(true);
    }
  }

  onNameChange(name: string): void {
    this.contractForm.patchValue({ name });
    this.activeSaveChange.emit(true);
    this.contractExist = false;
  }

  onCheckBoxChange(i: number, e: unknown): void {
    this.appsArray.at(i).setValue(e);
    // console.log(e, this.appsArray, this.f);
    this.activeSaveChange.emit(true);
  }

  errorHandle(error: HttpErrorResponse): void {
    this.loadingService.stop();
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Your Changes Can't be Saved.";
    modalInstance.instance.body =
      "Error occurs. Please refresh your screen to see updates.";
    modalInstance.result.then((result) => {
      if (result === 0) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl("blank").then(() => {
          this.router.navigateByUrl(currentUrl);
        });
      }
    });
  }
}
