import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BoroughNavigation, GlobalNavigation } from "../api-generated";
import { Borough } from "../api-generated/model/borough";
import { LoginUser } from "@aecom/core";

@Injectable()
export default class NavService {
  protected dataSubject = new BehaviorSubject<Borough[]>([]);

  data$: Observable<Borough[]> = this.dataSubject.asObservable();

  protected selectedSubject = new BehaviorSubject<Borough>(null);

  selectedItem$: Observable<Borough> = this.selectedSubject.asObservable();

  private broughDataSubject = new BehaviorSubject<BoroughNavigation[]>([]);

  broughDataSubject$: Observable<BoroughNavigation[]> =
    this.broughDataSubject.asObservable();

  private globalDataSubject = new BehaviorSubject<GlobalNavigation[]>([]);

  globalDataSubject$: Observable<GlobalNavigation[]> =
    this.globalDataSubject.asObservable();

  private userSubject = new BehaviorSubject<LoginUser>(null);

  user$: Observable<LoginUser> = this.userSubject.asObservable();

  setBroughData(item: BoroughNavigation[]): void {
    this.broughDataSubject.next(item);
  }

  getBroughData(): BoroughNavigation[] {
    return this.broughDataSubject.getValue();
  }

  setGlobalData(item: GlobalNavigation[]): void {
    this.globalDataSubject.next(item);
  }

  getGlobalData(): GlobalNavigation[] {
    return this.globalDataSubject.getValue();
  }

  setSelectedId(id: string): void {
    if (this.dataSubject.getValue()) {
      const items = this.dataSubject.getValue().filter((c) => c.Guid === id);
      if (items && items.length) {
        this.selectedSubject.next(items[0]);
      } else {
        this.selectedSubject.next(null);
      }
    }
  }

  getSelected(): Borough {
    return this.selectedSubject.getValue();
  }

  getrecentvalue(): Borough[] {
    return this.dataSubject.getValue();
  }

  setDataSubject(data: Borough[]): void {
    this.dataSubject.next(data);
  }

  getUser(): LoginUser {
    return this.userSubject.getValue();
  }

  setUser(user: LoginUser): void {
    this.userSubject.next(user);
  }

  setUserItem(firstName: string, lastName: string, id: string, email: string, companyName: string, permission: string[]): void {
    const user = new LoginUser(
      firstName,
      lastName,
      id,
      email,
      companyName,
      permission.join(),
    );
    this.userSubject.next(user);
  }
}
