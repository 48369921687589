<div class="container-fluid" *ngIf="contract">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/contracts']"> Contract &gt; </a>
              <span> {{ contract.Guid ? "Edit Contract" : "Add Contract" }}</span>
            </p>
            <h1>
              <span style="text-transform: uppercase">{{ contract.Guid ? "Edit Contract" : "Add Contract" }}</span>
            </h1>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button innerClass="btn lg outline orange" (click)="cancel()"> back </aecom-button>

            <aecom-button
              id="saveButton"
              innerClass="btn blue btn-icon solid"
              icon="icon icon-black icon-basic_save"
              (click)="onSubmit()"
            >
              <span> Save </span>
            </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <aecom-form-group
          columnSpan="6"
          columns="2"
          title="Contract Information"
          style="margin-bottom: 40px"
          class="matchToTab"
        >
          <aecom-form-item [label]="'Borough'" columnSpan="1">
            <aecom-dropdown
              [theme]="'light'"
              placeholder="[Borough]"
              [data]="boroughList"
              [selectedId]="contract.BoroughId"
              (ngValueChange)="setBorough($event)"
              [validate]="submitted && (!contract.BoroughId || contract.BoroughId.trim() === '')"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'NTP Date'" columnSpan="1">
            <aecom-date
              [weekendsOff]="true"
              [notEmpty]="true"
              [theme]="'light'"
              [ngValue]="ntpDate"
              (change)="setDate($event)"
              [validate]="submitted && !ntpDate"
            >
            </aecom-date>
          </aecom-form-item>
          <aecom-form-item [label]="'Contract Official Name'" columnSpan="1">
            <aecom-textbox
              theme="light"
              [notEmpty]="true"
              [(ngValue)]="contract.Name"
              (ngValueChange)="setName($event)"
              [validate]="submitted && (!contract.Name || contract.Name.trim() === '')"
            ></aecom-textbox>
            <div class="error-message" *ngIf="submitted && contractExist">Name Exist</div>
          </aecom-form-item>

          <aecom-form-item [label]="'Contract Short Name'" columnSpan="1">
            <aecom-textbox
              theme="light"
              [notEmpty]="true"
              [(ngValue)]="code"
              placeholder="DSS or GAR, etc."
              (ngValueChange)="setCode($event)"
              [validate]="submitted && (!code || code.trim() === '')"
            ></aecom-textbox>
            <div class="error-message" *ngIf="submitted && codeExist">Code Exist</div>
            <div class="error-message" *ngIf="!codeValid">Please don't include the Borough Code and '-'</div>
          </aecom-form-item>

          <aecom-form-item [label]="'PIN Number (Optional)'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.Pin"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'FMS ID'" columnSpan="1">
            <aecom-textbox
              theme="light"
              [notEmpty]="true"
              [(ngValue)]="contract.FMSID"
              [validate]="submitted && (!contract.FMSID || contract.FMSID.trim() === '')"
            ></aecom-textbox>
          </aecom-form-item>
        </aecom-form-group>

        <aecom-form-group
          columnSpan="6"
          columns="2"
          title="Assigned Project Manager"
          style="margin-bottom: 40px"
          class="matchToTab"
        >
          <aecom-form-item [label]="'AHJV PM Name'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.AHJVPMName"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'AHJV PM Email'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.AHJVPMEmail"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'DDC PM Name'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.DDCPMName"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'DDC PM Email'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.DDCPMEmail"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'DB PM Name'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.DBPMName"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'DB PM Email'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.DBPMEmail"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Bluebeam Manager'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.BluebeamManagerName"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Bluebeam Manager Email'" columnSpan="1">
            <aecom-textbox theme="light" [(ngValue)]="contract.BluebeamManagerEmail"></aecom-textbox>
          </aecom-form-item>
        </aecom-form-group>

        <aecom-form-group
          columnSpan="6"
          columns="1"
          title="Module Access"
          style="margin-bottom: 40px"
          class="matchToTab"
        >
          <div *ngFor="let app of applicationList">
            <div>
              <aecom-checkbox [(ngValue)]="app.checked" theme="light">
                {{ app.name }}
              </aecom-checkbox>
            </div>
          </div>
        </aecom-form-group>

        <aecom-form-group columnSpan="6" columns="2" title="Links" style="margin-bottom: 40px" class="matchToTab">
          <aecom-form-item [label]="'DDC/AHJV Contract Workspace URL'" columnSpan="2">
            <aecom-textbox theme="light" [(ngValue)]="contract.WorkSpaceUrl"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'AHJV Contract Workspace URL'" columnSpan="2">
            <aecom-textbox theme="light" [(ngValue)]="contract.AHJVWorkSpaceUrl"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Dashboard URL'" columnSpan="2">
            <aecom-textbox theme="light" [(ngValue)]="contract.ActivityUrl"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'BIM URL'" columnSpan="2">
            <aecom-textbox theme="light" [(ngValue)]="contract.Bim360Url"></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'DBT Collaboration Workspace URL'" columnSpan="2">
            <aecom-textbox theme="light" [(ngValue)]="contract.DBTCollaborationWorkspaceURL"></aecom-textbox>
          </aecom-form-item>
        </aecom-form-group>

        <aecom-form-group
          columnSpan="6"
          columns="2"
          title="Contract Status"
          style="margin-bottom: 40px"
          class="matchToTab"
          *ngIf="contract.Guid"
        >
          <aecom-form-item columnSpan="1">
            <div class="button-wrapper d-flex">
              <aecom-button
                innerClass="btn solid btn-tab {{ contract.InActive === false ? 'blue' : 'bg-grey-e' }}"
                (click)="setInActive(false)"
              >
                Active
              </aecom-button>

              <aecom-button
                innerClass="btn solid btn-tab {{ contract.InActive === true ? 'blue' : 'bg-grey-e' }}"
                (click)="setInActive(true)"
              >
                Inactive
              </aecom-button>
            </div>
          </aecom-form-item>
        </aecom-form-group>
      </aecom-form>
    </div>
  </div>
</div>
