import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@bbj/components";

@Component({
  selector: "app-singout",
  templateUrl: "./singout.component.html",
  styleUrls: ["./singout.component.scss"],
})
export default class SingoutComponent {
  constructor(public authService: AuthService, public router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.activeRoute.routeConfig.path === "logout") {
      this.authService.logout();
    }
  }

  login(): void {
    this.router.navigate(["/"]);
  }
}
